import React from "react";
import "./assets/scss/style.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import Homepage from "./views/Components/homepage.jsx";
import DataRecovery from "./views/Components/services/datarecovery.jsx";
import JunkRemoval from "./views/Components/services/junkremoval.jsx";
import PcMaintenance from "./views/Components/services/pcmaintenance.jsx";
import RemoteSupport from "./views/Components/services/remotesupport.jsx";
import Servicerequest from "./views/Components/services/Servicerequest.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Routes>
      {/* Homepage Route */}
      <Route path="/" element={<Homepage />} />
      
      {/* Service Routes */}
      <Route path="/Servicerequest" element={<Servicerequest />} />
      <Route path="/datarecovery" element={<DataRecovery />} />
      <Route path="/junkremoval" element={<JunkRemoval />} />
      <Route path="/pcmaintenance" element={<PcMaintenance />} />
      <Route path="/remotesupport" element={<RemoteSupport />} />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
